import { template as template_a3b70c63f955410a873b81f56d8e1c8a } from "@ember/template-compiler";
const FKControlMenuContainer = template_a3b70c63f955410a873b81f56d8e1c8a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
